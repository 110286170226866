import { ValidatorMessageProvider } from '@oriola-corporation/ui-components/lib/esm/forms';
import { SessionExpiredModal } from '@packages/auth';
import { getConfigurationVariable } from '@packages/config';
import { TranslationsProvider } from '@packages/translations';
import type { GatsbyBrowser } from 'gatsby';
import { Script, ScriptStrategy } from 'gatsby';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PreviousLocationProvider } from './contexts/PreviousLocationContext';
import { CartSyncer } from './templates/prescription/CartSyncer';
import { isApp } from './utils/mobileAppUtils';

const TranslatedValidationMessagesProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation('common');

  return (
    <ValidatorMessageProvider messages={t('forms.validation')}>{children}</ValidatorMessageProvider>
  );
};

const GoogleAnalyticsScripts: React.FC = () => {
  return (
    <Script strategy={ScriptStrategy.idle}>
      {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl+'';f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer', '${getConfigurationVariable(
      'GOOGLE_TAG_MANAGER_ID'
    )}');`}
    </Script>
  );
};

const wrapElement: GatsbyBrowser['wrapPageElement'] = ({ element, props }) => {
  return (
    <TranslationsProvider>
      {!isApp() && <GoogleAnalyticsScripts />}
      <TranslatedValidationMessagesProvider>
        {!isApp() && <SessionExpiredModal />}
        <CartSyncer />
        <PreviousLocationProvider pathname={props.location.pathname}>
          {element}
        </PreviousLocationProvider>
      </TranslatedValidationMessagesProvider>
    </TranslationsProvider>
  );
};
// eslint-disable-next-line import/no-default-export
export default wrapElement;
