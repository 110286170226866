export const SEARCH_PAGE_VIEWED = 'SEARCH_PAGE/VIEWED';
export interface SearchPageViewed {
  type: typeof SEARCH_PAGE_VIEWED;
  payload: {
    searchTerm: string;
  };
}

export const EMPTY_SEARCH_RESULT_VIEWED = 'SEARCH_PAGE/EMPTY_SEARCH_RESULT_VIEWED';
export interface EmptySearchResultViewed {
  type: typeof EMPTY_SEARCH_RESULT_VIEWED;
  payload: {
    searchTerm: string;
  };
}

export const SEARCH_FLYOUT_ACTIVATED = 'SEARCH_FLYOUT/ACTIVATED';

export interface SearchFlyoutActivated {
  type: typeof SEARCH_FLYOUT_ACTIVATED;
}

export const SEARCH_FLYOUT_QUERY_CHANGED = 'SEARCH_FLYOUT/QUERY_CHANGED';

export interface SearchFlyoutQueryChanged {
  type: typeof SEARCH_FLYOUT_QUERY_CHANGED;
  payload: {
    searchTerm: string;
    hasBrandSuggestions: boolean;
  };
}

export const SEARCH_FLYOUT_SUGGESTION_CLICKED = 'SEARCH_FLYOUT/SUGGESTION_CLICKED';
export type SearchFlyoutSuggestionClicked = {
  type: typeof SEARCH_FLYOUT_SUGGESTION_CLICKED;
  payload: {
    position?: number;
    text: string;
  };
};

export const SEARCH_FLYOUT_SUBMITTED = 'SEARCH_FLYOUT/SUBMITTED';
export type SearchFlyoutSubmitted = {
  type: typeof SEARCH_FLYOUT_SUBMITTED;
  payload: {
    searchTerm: string;
    hasBrandSuggestions: boolean;
  };
};

export const SEARCH_FLYOUT_CLOSED = 'SEARCH_FLYOUT/CLOSED';
export type SearchFlyoutClosed = {
  type: typeof SEARCH_FLYOUT_CLOSED;
  payload: {
    searchTerm: string;
  };
};

export const SEARCH_FLYOUT_BRAND_CLICKED = 'SEARCH_FLYOUT/BRAND_CLICKED';
export type SearchFlyoutBrandClicked = {
  type: typeof SEARCH_FLYOUT_BRAND_CLICKED;
};

export type AnalyticsEvents =
  | SearchPageViewed
  | EmptySearchResultViewed
  | SearchFlyoutActivated
  | SearchFlyoutQueryChanged
  | SearchFlyoutSuggestionClicked
  | SearchFlyoutSubmitted
  | SearchFlyoutClosed
  | SearchFlyoutBrandClicked;
