import axios from 'axios';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isDoktorSe } from '../../utils/doktorSeAppUtils';
import { isApp } from '../../utils/mobileAppUtils';
import { CartItemParams, createApiClient } from './api/createApiClient';
import { InvalidCartError } from './api/errors';
import { CartContext, CartContextType } from './Context';
import { Cart, MedicalCounselingPreferences, PrescribedDetailsRequested } from './types';
// eslint-disable-next-line implicit-dependencies/no-implicit
import { useAuth } from '@packages/auth';

export interface Store<T> {
  get: () => T | null | undefined;
  store: (id: T) => void;
  clear: () => void;
}

interface Props {
  baseUrl: string;
  cartIdStore: Store<string>;
  userIdStore: Store<string>;
  getSalesChannel: () => string | undefined;
  getPostalCode: () => string | undefined;
  enabled?: boolean;
  memberAccessToken?: string | null;
}

const isClientErrorStatusCode = (status: number) => status >= 400 && status < 500;

export const CartProvider: React.FC<React.PropsWithChildren<Props>> = ({
  baseUrl,
  cartIdStore,
  userIdStore,
  getSalesChannel,
  getPostalCode,
  children,
  enabled = true,
  memberAccessToken,
}) => {
  const apiClient = useMemo(
    () => createApiClient(baseUrl, getSalesChannel()),
    [baseUrl, getSalesChannel]
  );
  const { isSignedIn } = useAuth();
  const [cart, setCartState] = useState<Cart | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);

  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);
  const setCart = useCallback((cart: Cart | null) => {
    if (isMounted.current) {
      setCartState(cart);
    }
  }, []);

  const lastSubmittedAccessToken = useRef<string | null>(null);
  useEffect(() => {
    if (isApp()) {
      return;
    }
    const updateMemberSession = async (memberAccessToken: string) => {
      const cartId = cartIdStore.get();

      if (cartId && !isDoktorSe()) {
        try {
          const newCart = await apiClient.updateMemberSession(cartId, memberAccessToken);
          setCart(newCart);
        } catch (e) {
          // No need to handle this error as either the member is not a valid member
          // or the token is expired
        }
      }
    };

    const removeMemberSession = async () => {
      const cartId = cartIdStore.get();

      if (cartId) {
        try {
          const newCart = await apiClient.removeMemberSession(cartId);
          setCart(newCart);
        } catch (e) {
          // No need to catch this case as either the member is not a valid member
        }
      }
    };

    if (memberAccessToken) {
      if (!isSignedIn && cart?.memberSession) {
        removeMemberSession();
      }
      // TODO: remove this replace when we have fixed the token in the backend
      const strippedToken = memberAccessToken?.replace('Bearer ', '');
      if (strippedToken && strippedToken !== lastSubmittedAccessToken.current && cart) {
        updateMemberSession(strippedToken);
        lastSubmittedAccessToken.current = strippedToken;
      }
    } else {
      lastSubmittedAccessToken.current = null;
      if (cart?.memberSession) {
        removeMemberSession();
      }
    }
  }, [apiClient, cart, cartIdStore, isSignedIn, memberAccessToken, setCart]);

  const createNewCart = useCallback(async () => {
    cartIdStore.clear();
    setHasErrors(false);
    try {
      const newCart = await apiClient.createCart({
        postalCode: getPostalCode(),
        userId: userIdStore.get(),
      });

      return newCart;
    } catch (e) {
      setHasErrors(true);

      throw e;
    }
  }, [apiClient, cartIdStore, userIdStore, getPostalCode]);

  const fetchCart = useCallback(async () => {
    const cartId = cartIdStore.get();
    if (cartId) {
      try {
        const cart = await apiClient.getCart(cartId);

        if (cart.orderCreated) {
          return createNewCart();
        }

        return cart;
      } catch (e) {
        if (axios.isAxiosError(e) && isClientErrorStatusCode(e.response?.status ?? 0)) {
          return createNewCart();
        }

        setHasErrors(true);
        throw e;
      }
    }

    return createNewCart();
  }, [apiClient, cartIdStore, createNewCart]);

  const refresh = useCallback(async () => {
    try {
      const cart = await fetchCart();

      setCart(cart);
    } catch (e) {
      // Ignore error as if we got to this point
      // An error modal will show on the page
    }
  }, [fetchCart, setCart]);

  useEffect(() => {
    if (cart) {
      cartIdStore.store(cart.cartId);
    }
  }, [cart, cartIdStore]);

  useEffect(() => {
    const getCart = async () => {
      setIsFetching(true);
      try {
        const cart = await fetchCart();
        setCart(cart);
      } catch (e) {
        // Ignore error as if we got to this point
        // An error modal will show on the page
      }
      isMounted.current && setIsFetching(false);
    };

    if (enabled) {
      getCart();
    }
  }, [fetchCart, setCart, isMounted, enabled]);

  useEffect(() => {
    window.onpageshow = (event) => {
      if (event.persisted) {
        refresh();
      }
    };
  }, [refresh]);

  const updateWantsToBecomeMember = useCallback(
    async (wantsToBecomeMember: boolean) => {
      const cartId = cartIdStore.get();
      if (cartId) {
        await apiClient.touch(cartId, { wantsToBecomeMember });
        return await refresh();
      }
    },
    [apiClient, cartIdStore, refresh]
  );

  const touch = useCallback(async () => {
    const cartId = cartIdStore.get();

    if (cartId) {
      await apiClient.touch(cartId, {
        touchRx: true,
      });
    }
  }, [apiClient, cartIdStore]);

  const destroyRx = useCallback(async () => {
    const cartId = cartIdStore.get();

    if (cartId) {
      const cart = await apiClient.destroyRx(cartId);
      setCart(cart);
    }
  }, [apiClient, setCart, cartIdStore]);

  const addBonusCheck = useCallback(
    async (bonusCheckId: string) => {
      const cartId = cartIdStore.get();

      if (cartId) {
        const cart = await apiClient.addBonusCheck(cartId, bonusCheckId);
        setCart(cart);
      }
    },
    [apiClient, setCart, cartIdStore]
  );

  const removeBonusCheck = useCallback(
    async (bonusCheckId: string) => {
      const cartId = cartIdStore.get();

      if (cartId) {
        const cart = await apiClient.removeBonusCheck(cartId, bonusCheckId);
        setCart(cart);
      }
    },
    [apiClient, setCart, cartIdStore]
  );

  const addPrescriptionBag = useCallback(
    async (prescriptionBagId: string) => {
      const cartId = cartIdStore.get();

      if (cartId) {
        const cart = await apiClient.addPrescriptionBag(cartId, prescriptionBagId);
        setCart(cart);
      }
    },
    [apiClient, cartIdStore, setCart]
  );

  const removePrescriptionBag = useCallback(async () => {
    const cartId = cartIdStore.get();

    if (cartId) {
      const cart = await apiClient.removePrescriptionBag(cartId);
      setCart(cart);
    }
  }, [apiClient, cartIdStore, setCart]);

  const initiateCheckout = useCallback(async () => {
    const cartId = cartIdStore.get();
    if (cartId) {
      try {
        const newCart = await apiClient.initiateCheckout(cartId);
        setCart(newCart);
      } catch (e) {
        setHasErrors(true);
      }
    }
  }, [apiClient, cartIdStore, setCart]);

  const confirmByPaymentId = useCallback(
    async (paymentId: string) => {
      const confirmResponse = await apiClient.confirmByPaymentId(paymentId);

      refresh();

      return confirmResponse;
    },
    [apiClient, refresh]
  );

  const updatePrescribedDetailsInformationPreferences = useCallback(
    async (preferences: PrescribedDetailsRequested) => {
      const cartId = cartIdStore.get();

      if (cartId) {
        const cart = await apiClient.updatePrescribedDetailsInformationPreferences(
          cartId,
          preferences
        );
        setCart(cart);
      }
    },
    [apiClient, cartIdStore, setCart]
  );

  const updateMedicalCounselingPreferences = useCallback(
    async (preferences: MedicalCounselingPreferences) => {
      const cartId = cartIdStore.get();

      if (cartId) {
        const cart = await apiClient.updateMedicalCounselingPreferences(cartId, preferences);
        setCart(cart);
      }
    },
    [apiClient, cartIdStore, setCart]
  );

  const addItemToCart = useCallback(
    async (itemId: string, quantity: number) => {
      if (cart) {
        try {
          const newCart = await apiClient.addItemToCart(cart.cartId, itemId, { quantity });
          setCart(newCart);
        } catch (error) {
          if (error instanceof InvalidCartError) {
            const newCart = await createNewCart();
            setCart(newCart);
          }

          throw error;
        }
      }
    },
    [cart, setCart, apiClient, createNewCart]
  );

  const addItemsToCart = useCallback(
    async (items: CartItemParams[]) => {
      if (cart) {
        try {
          const newCart = await apiClient.addItemsToCart(cart.cartId, items);
          setCart(newCart);
        } catch (error) {
          if (error instanceof InvalidCartError) {
            const newCart = await createNewCart();
            setCart(newCart);
          }

          throw error;
        }
      }
    },
    [cart, setCart, apiClient, createNewCart]
  );

  const updateItemInCart = useCallback(
    async (itemId: string, quantity: number) => {
      if (cart) {
        try {
          const newCart = await apiClient.updateItemInCart(cart.cartId, itemId, { quantity });
          setCart(newCart);
        } catch (error) {
          if (error instanceof InvalidCartError) {
            const newCart = await createNewCart();
            setCart(newCart);
          }

          throw error;
        }
      }
    },
    [apiClient, cart, createNewCart, setCart]
  );

  const deleteItemFromCart = useCallback(
    async (itemId: string) => {
      if (cart) {
        try {
          const newCart = await apiClient.deleteItemFromCart(cart.cartId, itemId);
          setCart(newCart);
        } catch (error) {
          if (error instanceof InvalidCartError) {
            const newCart = await createNewCart();
            setCart(newCart);
          }

          throw error;
        }
      }
    },
    [apiClient, cart, createNewCart, setCart]
  );

  const addVoucherToCart = useCallback(
    async (voucherCode: string) => {
      if (cart) {
        const newCart = await apiClient.addVoucherToCart(cart.cartId, voucherCode);
        setCart(newCart);
      }
    },
    [apiClient, cart, setCart]
  );

  const deleteVoucherFromCart = useCallback(
    async (voucherCode: string) => {
      if (cart) {
        const newCart = await apiClient.deleteVoucherFromCart(cart.cartId, voucherCode);
        setCart(newCart);
      }
    },
    [apiClient, cart, setCart]
  );
  const onCartChangeStart = apiClient.onCartChangeStart;

  const onCartChangeEnd = apiClient.onCartChangeEnd;

  const contextValue: CartContextType = useMemo(
    () => ({
      cart,
      isUpdating: isFetching,
      initiateCheckout,
      hasErrors,
      refresh,
      touch,
      updateWantsToBecomeMember,
      destroyRx,
      addBonusCheck,
      removeBonusCheck,
      confirmByPaymentId,
      addPrescriptionBag,
      removePrescriptionBag,
      updatePrescribedDetailsInformationPreferences,
      updateMedicalCounselingPreferences,
      addItemToCart,
      addItemsToCart,
      updateItemInCart,
      deleteItemFromCart,
      addVoucherToCart,
      deleteVoucherFromCart,
      onCartChangeStart,
      onCartChangeEnd,
    }),
    [
      cart,
      isFetching,
      initiateCheckout,
      hasErrors,
      refresh,
      touch,
      updateWantsToBecomeMember,
      destroyRx,
      addBonusCheck,
      removeBonusCheck,
      confirmByPaymentId,
      addPrescriptionBag,
      removePrescriptionBag,
      updatePrescribedDetailsInformationPreferences,
      updateMedicalCounselingPreferences,
      addItemToCart,
      addItemsToCart,
      updateItemInCart,
      deleteItemFromCart,
      addVoucherToCart,
      deleteVoucherFromCart,
      onCartChangeStart,
      onCartChangeEnd,
    ]
  );

  return <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>;
};
