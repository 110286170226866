// eslint-disable-next-line no-restricted-imports
import { authyApiClient, useAuth } from '@packages/auth/AuthProvider';
import { CartProvider } from '@packages/cart';
import { getConfigurationVariable } from '@packages/config';
import { isServer } from '@packages/gatsby-utils';
import Cookies from 'js-cookie';
import React, { useEffect } from 'react';
import { useCookieConsent } from '../cookiebot';
import { MemberApi } from '../queries/member/MemberApi';

export const CART_UPDATED = 'CART_UPDATED';
export const CART_PRODUCT_CLICKED = 'CART_PRODUCT_CLICKED';

const CART_ID_COOKIE = 'kronan-cartId';
const USER_ID_COOKIE = 'kronan-userId';

const cartIdStore = {
  get: () => Cookies.get(CART_ID_COOKIE),
  store: (cartId: string) =>
    Cookies.set(CART_ID_COOKIE, cartId, { expires: 7, sameSite: 'none', secure: true }),
  clear: () => Cookies.remove(CART_ID_COOKIE),
};

const userIdStore = {
  get: () => Cookies.get(USER_ID_COOKIE),
  store: (userId: string) =>
    Cookies.set(USER_ID_COOKIE, userId, {
      expires: 1000,
      sameSite: 'none',
      secure: true,
    }),
  clear: () => Cookies.remove(USER_ID_COOKIE),
};

const getSalesChannel = () => {
  if (!isServer() && window.location.pathname.includes('doktor-se')) {
    return 'DOKTOR_SE';
  }
};

const getPostalCode = () => {
  const postalCode = localStorage.getItem('kronan-postalCode');

  if (postalCode) {
    return postalCode;
  }
};

export const ConfiguredCartProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { hasAcceptedNecessaryCookies } = useCookieConsent();
  const { isSignedIn } = useAuth();
  const { data: memberStatus } = MemberApi.useGetMemberStatus();
  const [memberAccessToken, setMemberAccessToken] = React.useState<string | null>(null);

  useEffect(() => {
    const isMemberSessionDisabled =
      !memberStatus ||
      memberStatus.status === 'NON_EXISTENT' ||
      memberStatus.status === 'REMOVED' ||
      memberStatus.status === 'DELETED';
    const fetchMemberAccessToken = async () => {
      const data = await authyApiClient.getAccessToken();
      setMemberAccessToken(data.token);
    };

    if (isSignedIn && !isMemberSessionDisabled) {
      fetchMemberAccessToken();
    }
  }, [isSignedIn, memberStatus]);

  return (
    <CartProvider
      baseUrl={`${new URL(
        getConfigurationVariable('CART_API_URL'),
        getConfigurationVariable('WEB_API_BASE_URL')
      )}`}
      cartIdStore={cartIdStore}
      userIdStore={userIdStore}
      getSalesChannel={getSalesChannel}
      getPostalCode={getPostalCode}
      enabled={hasAcceptedNecessaryCookies}
      memberAccessToken={memberAccessToken}
    >
      {children}
    </CartProvider>
  );
};
