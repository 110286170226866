import {
  ApiClient as AvailabilityApiClient,
  createApiClient as createAvailabilityApiClient,
} from '@apis/availability';
import {
  ApiClient as BiznizApiClient,
  createApiClient as createBiznizApiClient,
} from '@apis/bizniz';
import {
  ApiClient as DeliveryParametersApiClient,
  createApiClient as createDeliveryParametersApiClient,
} from '@apis/delivery-parameters';
import {
  ApiClient as ExpeditionApiClient,
  convertToExpeditionError,
  createApiClient as createExpeditionApi,
} from '@apis/expedition';
import {
  ApiClient as ItemWebApiClient,
  createApiClient as createItemWebApiClient,
  Product,
} from '@apis/item-web';
import {
  createApiClient as createMemberApiClient,
  MemberApiClient,
  convertToMemberError,
} from '@apis/member';
import {
  ApiClient as ParcelTrackerApiClient,
  createApiClient as createParcelTrackerApiClient,
} from '@apis/parcel-tracker';
import {
  ApiClient as RestrictionApiClient,
  createApiClient as createRestrictionApiClient,
} from '@apis/restrictions';
import { VoyadoApiClient, createVoyadoApiClient } from '@apis/voyado/voyado';
import {
  ApiClient as WebNotifierApiClient,
  createApiClient as createWebNotifierApiClient,
} from '@apis/web-notifier';
import { useAuth } from '@packages/auth';
import { getConfigurationVariable } from '@packages/config';
import { makeGatsbyLink } from '@packages/gatsby-utils';
import React, { createContext, useContext, useEffect } from 'react';

const createGatsbyConnectedItemWebApiClient = (baseUrl?: string): ItemWebApiClient => {
  const baseClient = createItemWebApiClient(baseUrl);
  const resolveItem = (item: Product) => ({
    ...item,
    url: makeGatsbyLink(item.url),
  });

  return {
    getItem: async (itemId: string) => {
      const item = await baseClient.getItem(itemId);

      return resolveItem(item);
    },
    getItems: async (itemIds: string[]) => {
      const items = await baseClient.getItems(itemIds);

      return items.map(resolveItem);
    },
  };
};

interface ApiContextType {
  availability: AvailabilityApiClient;
  bizniz: BiznizApiClient;
  deliveryParameters: DeliveryParametersApiClient;
  expedition: ExpeditionApiClient;
  itemWeb: ItemWebApiClient;
  parcelTracker: ParcelTrackerApiClient;
  webNotifier: WebNotifierApiClient;
  restriction: RestrictionApiClient;
  member: MemberApiClient;
  voyado: VoyadoApiClient;
}

const ApiContext = createContext<ApiContextType>({
  availability: createAvailabilityApiClient(getConfigurationVariable('AVAILABILITY_API_BASE_URL')),
  bizniz: createBiznizApiClient(getConfigurationVariable('BIZNIZ_API_BASE_URL')),
  deliveryParameters: createDeliveryParametersApiClient(
    getConfigurationVariable('DELIVERY_PARAMETERS_API_BASE_URL')
  ),
  expedition: createExpeditionApi(getConfigurationVariable('EXPEDITION_API_BASE_URL')),
  itemWeb: createGatsbyConnectedItemWebApiClient(getConfigurationVariable('ITEM_WEB_API_BASE_URL')),
  parcelTracker: createParcelTrackerApiClient(getConfigurationVariable('PARCEL_TRACKER_BASE_URL')),
  webNotifier: createWebNotifierApiClient(getConfigurationVariable('WEB_NOTIFIER_BASE_URL')),
  restriction: createRestrictionApiClient(getConfigurationVariable('RESTRICTIONS_API_BASE_URL')),
  member: createMemberApiClient(getConfigurationVariable('MEMBER_BASE_URL')),
  voyado: createVoyadoApiClient(),
});

export const useApi = () => useContext(ApiContext);

export const AuthenticatedApiProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { authenticateAxiosClient } = useAuth();

  const contextValue = useApi();

  useEffect(() => {
    const authenticatedClients = [
      authenticateAxiosClient(contextValue.expedition.getAxiosInstance(), async (error) => {
        throw convertToExpeditionError(error);
      }),
      authenticateAxiosClient(contextValue.member.getAxiosInstance(), async (error) => {
        throw convertToMemberError(error);
      }),
      authenticateAxiosClient(contextValue.bizniz.getAxiosInstance()),
    ];
    return () => {
      authenticatedClients.forEach((removeAuthentication) => removeAuthentication());
    };
  }, [authenticateAxiosClient, contextValue.bizniz, contextValue.expedition, contextValue.member]);

  return <ApiContext.Provider value={contextValue}>{children}</ApiContext.Provider>;
};
