import { isServer } from '@packages/gatsby-utils';
import Cookies from 'js-cookie';
import React, { useEffect, useState, createContext, useContext } from 'react';
import { isApp } from './utils/mobileAppUtils';

export const cookiebotInit = () => {
  return (
    <script
      key={`cookiebot-init`}
      id="Cookiebot"
      src="https://consent.cookiebot.com/uc.js"
      data-cbid="6d8f1ea5-b188-49f3-869b-79f19631389c"
      async
      type="text/javascript"
    />
  );
};

export type CookieCategory = 'necessary' | 'statistics' | 'marketing' | 'preferences';

type ConsentType = {
  marketing: boolean;
  method: string | null;
  necessary: boolean;
  preferences: boolean;
  stamp: string;
  statistics: boolean;
};

export interface WindowWithCookieConsent extends Window {
  CookieConsent?: {
    consent: ConsentType;
    whitelist: string[];
    hide: () => void;
  };
}

type CookieConsentContextType = {
  hasAcceptedNecessaryCookies: boolean;
  hasAcceptedPreferencesCookies: boolean;
  hasAcceptedStatisticsCookies: boolean;
  hasAcceptedMarketingCookies: boolean;
  acceptedCategories: CookieCategory[];
  isInitialized: boolean;
  getCategoryStatus: (category: CookieCategory) => boolean;
};

const CookieConsentContext = createContext<CookieConsentContextType | undefined>(undefined);

/**
 * This function is used when you need to observe the cookie consent outside of the CookieConsentProvider
 *
 * @param onLoad
 * @param CookieCategory
 */
export const observeCookies = (onLoad: (bool: boolean) => void, CookieCategory: CookieCategory) => {
  const w = !isServer()
    ? (window as WindowWithCookieConsent)
    : {
        CookieConsent: null,
      };

  const onCookiebotLoad = () => {
    onLoad(!!w.CookieConsent?.consent[CookieCategory]);
  };

  if (w.CookieConsent) {
    onCookiebotLoad();
  }

  window.addEventListener('CookiebotOnAccept', onCookiebotLoad);
  window.addEventListener('CookiebotOnDecline', onCookiebotLoad);
};

export const CookieConsentProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [cookieConsent, setCookieConsent] = useState<ConsentType | null>(null);
  const w = !isServer()
    ? (window as WindowWithCookieConsent)
    : {
        CookieConsent: null,
      };

  useEffect(() => {
    const onAcceptCookies = () => {
      if (w.CookieConsent) {
        setCookieConsent({ ...w.CookieConsent.consent });
      }
    };

    const onDeclineCookies = () => {
      if (w.CookieConsent) {
        setCookieConsent({ ...w.CookieConsent.consent });

        const cookieList = Cookies.get();

        // Remove cookies that are not whitelisted
        Object.keys(cookieList).forEach((cookie) => {
          if (
            w.CookieConsent &&
            !w.CookieConsent.whitelist.includes(cookie) &&
            cookie !== 'kronan-feature' &&
            cookie !== 'kronan-cartId' &&
            cookie !== 'CookieConsent'
          ) {
            Cookies.remove(cookie);
          }
        });
      }
    };

    // Prevent the cookie banner from showing in the app
    const onCookiebotLoad = () => {
      if (isApp()) {
        w.CookieConsent?.hide();
      } else if (w.CookieConsent) {
        setCookieConsent({ ...w.CookieConsent.consent });
      }
    };

    onCookiebotLoad();
    // These events are emmited by Cookiebot
    // Accept is triggered when looading an existing consent and on accept
    if (!isServer()) {
      window.addEventListener('CookiebotOnLoad', onCookiebotLoad);
      window.addEventListener('CookiebotOnDialogInit', onCookiebotLoad);
      window.addEventListener('CookiebotOnAccept', onAcceptCookies);
      window.addEventListener('CookiebotOnDecline', onDeclineCookies);
    }
    return () => {
      if (!isServer()) {
        window.removeEventListener('CookiebotOnLoad', onCookiebotLoad);
        window.removeEventListener('CookiebotOnDialogInit', onCookiebotLoad);
        window.removeEventListener('CookiebotOnAccept', onAcceptCookies);
        window.addEventListener('CookiebotOnDecline', onDeclineCookies);
      }
    };
  }, [w.CookieConsent]);

  // Method will be null until the user has interacted with the cookie banner
  const isInitialized = cookieConsent !== null;

  const categories: CookieCategory[] = ['necessary', 'preferences', 'statistics', 'marketing'];

  const acceptedCategories: CookieCategory[] = categories.filter(
    (category) => cookieConsent?.[category]
  );

  const getCategoryStatus = (category: CookieCategory) => cookieConsent?.[category] || false;

  const hasAcceptedNecessaryCookies = getCategoryStatus('necessary');
  const hasAcceptedPreferencesCookies = getCategoryStatus('preferences');
  const hasAcceptedStatisticsCookies = getCategoryStatus('statistics');
  const hasAcceptedMarketingCookies = getCategoryStatus('marketing');

  return (
    <CookieConsentContext.Provider
      value={{
        hasAcceptedNecessaryCookies,
        hasAcceptedPreferencesCookies,
        hasAcceptedStatisticsCookies,
        hasAcceptedMarketingCookies,
        acceptedCategories,
        isInitialized,
        getCategoryStatus,
      }}
    >
      {children}
    </CookieConsentContext.Provider>
  );
};

export const useCookieConsent = () => {
  const context = useContext(CookieConsentContext);
  if (!context) {
    throw new Error('useCookieConsent must be used within a CookieConsentProvider');
  }
  return context;
};
