import { AnalyticsEvents as CheckoutAnalyticsEvents } from '../checkout/analytics';
import { AnalyticsEvents as SearchFlyoutAnalyticsEvents } from '../components/search/analytics';
import { AnalyticsEvents as RxAnalyticsEvents } from '../templates/prescription/analytics';

export const PAGE_VIEWED = 'PAGE_VIEWED';
export interface PageViewed {
  type: typeof PAGE_VIEWED;
  payload: {
    url: string;
    isRXLoggedIn: boolean;
    isMemberLoggedIn: boolean;
  };
}

export type AnalyticsEvents =
  | PageViewed
  | CheckoutAnalyticsEvents
  | SearchFlyoutAnalyticsEvents
  | RxAnalyticsEvents;
