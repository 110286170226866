import { FeatureFlagsProvider } from '@packages/config';
import { TestFreaksProvider } from '@packages/testfreaks';
import React from 'react';
import { DatadogProvider } from '../../containers/integrations/datadogProvider';
import { useCookieConsent } from '../../cookiebot';

type PropsWithoutChildren<T extends React.ElementType> = Omit<React.ComponentProps<T>, 'children'>;

export type DatadogConfig = PropsWithoutChildren<typeof DatadogProvider>;

export type TestFreaksConfig = PropsWithoutChildren<typeof TestFreaksProvider>;

export type FeatureFlagsConfig = PropsWithoutChildren<typeof FeatureFlagsProvider>;

interface Props {
  datadog: DatadogConfig;
  testFreaks: TestFreaksConfig;
  featureFlags: FeatureFlagsConfig;
}

export const EnvironmentProvider: React.FC<React.PropsWithChildren<Props>> = ({
  datadog,
  testFreaks,
  featureFlags,
  children,
}) => {
  const { hasAcceptedStatisticsCookies } = useCookieConsent();
  return (
    <FeatureFlagsProvider {...featureFlags}>
      <TestFreaksProvider {...testFreaks}>
        <DatadogProvider {...datadog} enabled={hasAcceptedStatisticsCookies} />
        {children}
      </TestFreaksProvider>
    </FeatureFlagsProvider>
  );
};
