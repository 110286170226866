import axios from 'axios';
import { BonusCheck, ConfirmCartResponse, ItemRestriction } from '..';
import {
  Cart,
  MedicalCounselingPreferences,
  PrescribedDetailsRequested,
  TouchCartParams,
} from '../types';
import { sortArrayByAttribute } from '../utils/sortArrayByAttribute';
import { InvalidCartError, RestrictionError, InvalidVoucherError } from './errors';
import { NotAValidMemberError } from './errors/NotAValidMemberError';
// eslint-disable-next-line implicit-dependencies/no-implicit
import { voyadoSession } from '@apis/voyado/voyado';

export interface CreateCartParams {
  postalCode?: string | null;
  userId?: string | null;
}

export interface UpdateCartParams {
  rows: Array<{ itemId: string; quantity: number }>;
  postalCode?: string;
  voucherCodes: string[];
}

export interface CartItemParams {
  itemId: string;
  quantity: number;
}

export interface AddItemToCartParams {
  quantity: number;
}

export interface UpdateItemInCartParams {
  quantity: number;
}

export const sortCartItemsAndFixLegacyFields = (cart: Cart) => ({
  ...cart,
  rows: sortArrayByAttribute(
    cart.rows.map((row) => ({ ...row, marketingSensitive: row.marketingSensitive ?? true })),
    (row) => row.itemId
  ),
});

export const createApiClient = (baseUrl: string, salesChannel = 'B2C') => {
  const client = axios.create({
    baseURL: baseUrl,
  });

  const buildInternalUrl = (path: string) => `/internal/${salesChannel}${path}`;
  const buildInternalCartUrl = (cartId?: string) =>
    buildInternalUrl(`/cart${cartId ? `/${cartId}` : ''}`);

  const buildInternalCartUrlWithItemId = (cartId: string, itemId: string) =>
    buildInternalUrl(`/cart/${cartId}/tg/${itemId}`);

  const buildProtectedUrl = (path: string) => `/protected/${salesChannel}${path}`;
  const buildProtectedCartUrl = (cartId?: string) =>
    buildProtectedUrl(`/cart${cartId ? `/${cartId}` : ''}`);

  const buildAuthorizationHeaders = (accessToken: string) => ({
    Authorization: accessToken,
  });

  const buildCartUrl = (cartId?: string) =>
    `/internal/${salesChannel}/cart${cartId ? `/${cartId}` : ''}`;

  type CartListener = () => void;

  let startListeners: CartListener[] = [];
  let endListeners: CartListener[] = [];

  let requestCount = 0;
  const incrementRequestCount = () => {
    requestCount++;
    if (requestCount === 1) {
      startListeners.forEach((listener) => listener());
    }
  };

  const decrementRequestCount = () => {
    requestCount--;
    if (requestCount === 0) {
      endListeners.forEach((listener) => listener());
    }
  };

  client.interceptors.request.use((config) => {
    incrementRequestCount();
    return config;
  });

  client.interceptors.response.use(
    (response) => {
      decrementRequestCount();
      return response;
    },
    (error) => {
      decrementRequestCount();
      return Promise.reject(error);
    }
  );

  return {
    getAxiosInstance: () => client,
    onCartChangeStart: (listener: CartListener) => {
      startListeners.push(listener);
      return () => {
        startListeners = startListeners.filter((l) => l !== listener);
      };
    },
    onCartChangeEnd: (listener: CartListener) => {
      endListeners.push(listener);
      return () => {
        endListeners = endListeners.filter((l) => l !== listener);
      };
    },
    createCart: async (params: CreateCartParams) => {
      const response = await client.post<Cart>(buildInternalCartUrl(), params);

      return sortCartItemsAndFixLegacyFields(response.data);
    },
    getCart: async (cartId: string) => {
      const response = await client.get<Cart>(buildInternalCartUrl(cartId));

      return sortCartItemsAndFixLegacyFields(response.data);
    },
    addItemToCart: async (cartId: string, itemId: string, params: AddItemToCartParams) => {
      try {
        const response = await client.post<Cart>(
          buildInternalCartUrlWithItemId(cartId, itemId),
          params
        );

        return sortCartItemsAndFixLegacyFields(response.data);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error?.response?.status === 409) {
            const details = error.response.data[0] as ItemRestriction;
            throw new RestrictionError(details.customerDescription, details);
          }

          if (error?.response?.status === 403) {
            const { errorData } = error.response.data;
            throw new InvalidCartError(errorData as string);
          }
        }

        throw error;
      }
    },
    addItemsToCart: async (cartId: string, params: CartItemParams[]) => {
      try {
        const response = await client.post<Cart>(`${buildInternalCartUrl(cartId)}/tg`, params);

        return sortCartItemsAndFixLegacyFields(response.data);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error?.response?.status === 409) {
            const details = error.response.data[0] as ItemRestriction;
            throw new RestrictionError(details.customerDescription, details);
          }

          if (error?.response?.status === 403) {
            const { errorData } = error.response.data;
            throw new InvalidCartError(errorData as string);
          }
        }

        throw error;
      }
    },
    updateItemInCart: async (cartId: string, itemId: string, params: UpdateItemInCartParams) => {
      try {
        const response = await client.patch<Cart>(
          buildInternalCartUrlWithItemId(cartId, itemId),
          params
        );

        return sortCartItemsAndFixLegacyFields(response.data);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error?.response?.status === 409) {
            const details = error.response.data[0] as ItemRestriction;
            throw new RestrictionError(details.customerDescription, details);
          }

          if (error?.response?.status === 403) {
            const { errorData } = error.response.data;
            throw new InvalidCartError(errorData as string);
          }
        }

        throw error;
      }
    },

    deleteItemFromCart: async (cartId: string, itemId: string) => {
      try {
        const response = await client.delete<Cart>(buildInternalCartUrlWithItemId(cartId, itemId));

        return sortCartItemsAndFixLegacyFields(response.data);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error?.response?.status === 403) {
            const { errorData } = error.response.data;
            throw new InvalidCartError(errorData as string);
          }
        }

        throw error;
      }
    },
    addVoucherToCart: async (cartId: string, voucherCode: string) => {
      try {
        const response = await client.post<Cart>(
          `${buildInternalCartUrl(cartId)}/voucher/${voucherCode}`
        );

        return sortCartItemsAndFixLegacyFields(response.data);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error?.response?.status === 409) {
            throw new InvalidVoucherError(voucherCode, error.response.data.reason);
          }
        }

        throw error;
      }
    },

    deleteVoucherFromCart: async (cartId: string, voucherCode: string) => {
      try {
        const response = await client.delete<Cart>(
          `${buildInternalCartUrl(cartId)}/voucher/${voucherCode}`
        );

        return sortCartItemsAndFixLegacyFields(response.data);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error?.response?.status === 403) {
            const { errorData } = error.response.data;
            throw new InvalidCartError(errorData as string);
          }
        }

        throw error;
      }
    },
    addPrescriptionBag: async (cartId: string, prescriptionBagId: string) => {
      const response = await client.put<Cart>(`${buildInternalCartUrl(cartId)}/prescription-bag`, {
        prescriptionBagId,
      });

      return sortCartItemsAndFixLegacyFields(response.data);
    },
    removePrescriptionBag: async (cartId: string) => {
      const response = await client.delete<Cart>(
        `${buildInternalCartUrl(cartId)}/prescription-bag`
      );

      return sortCartItemsAndFixLegacyFields(response.data);
    },
    touch: async (cartId: string, params: TouchCartParams) => {
      const response = await client.patch<Cart>(buildInternalCartUrl(cartId), params);

      return sortCartItemsAndFixLegacyFields(response.data);
    },
    destroyRx: async (cartId: string) => {
      const response = await client.delete<Cart>(`${buildInternalCartUrl(cartId)}/rx`);

      return sortCartItemsAndFixLegacyFields(response.data);
    },
    initiateCheckout: async (cartId: string) => {
      const response = await client.post<Cart>(`${buildInternalCartUrl(cartId)}/checkout`);

      return sortCartItemsAndFixLegacyFields(response.data);
    },
    async syncState(cartId: string) {
      return this.initiateCheckout(cartId);
    },
    confirm: async (cartId: string) => {
      const response = await client.post<ConfirmCartResponse>(
        `${buildInternalCartUrl(cartId)}/confirm`
      );

      return response.data;
    },
    getBonusChecks: async (accessToken: string) => {
      const response = await client.get<BonusCheck[]>(buildProtectedUrl('/bonus-check'), {
        headers: buildAuthorizationHeaders(accessToken),
      });

      return response.data;
    },
    addBonusCheck: async (cartId: string, bonusCheckId: string) => {
      const response = await client.post<Cart>(`${buildInternalCartUrl(cartId)}/bonus-check`, {
        id: bonusCheckId,
      });

      return sortCartItemsAndFixLegacyFields(response.data);
    },
    removeBonusCheck: async (cartId: string, bonusCheckId: string) => {
      const response = await client.delete<Cart>(`${buildInternalCartUrl(cartId)}/bonus-check`, {
        data: {
          id: bonusCheckId,
        },
      });

      return sortCartItemsAndFixLegacyFields(response.data);
    },
    updateMemberSession: async (cartId: string, accessToken: string) => {
      try {
        const response = await client.put<Cart>(
          `${buildProtectedCartUrl(cartId)}/member/session`,
          null,
          {
            headers: buildAuthorizationHeaders(accessToken),
          }
        );

        return sortCartItemsAndFixLegacyFields(response.data);
      } catch (e) {
        if (axios.isAxiosError(e)) {
          if (e?.response?.status === 403) {
            throw new NotAValidMemberError('Not a valid member');
          }
        }

        throw e;
      }
    },
    removeMemberSession: async (cartId: string) => {
      const response = await client.delete<Cart>(`${buildInternalCartUrl(cartId)}/member/session`);

      return sortCartItemsAndFixLegacyFields(response.data);
    },
    confirmByPaymentId: async (paymentId: string) => {
      let url = `${buildCartUrl()}/paymentId/${paymentId}/confirm`;
      const { customerKey, sessionKey } = voyadoSession();

      if (customerKey && sessionKey) {
        url += `?customerKey=${customerKey}&sessionKey=${sessionKey}`;
      }

      const response = await client.post<ConfirmCartResponse>(url);

      return response.data;
    },
    updateMedicalCounselingPreferences: async (
      cartId: string,
      preferences: MedicalCounselingPreferences
    ) => {
      const response = await client.put<Cart>(
        `${buildInternalCartUrl(cartId)}/medical-counseling`,
        preferences
      );
      return sortCartItemsAndFixLegacyFields(response.data);
    },
    updatePrescribedDetailsInformationPreferences: async (
      cartId: string,
      preferences: PrescribedDetailsRequested
    ) => {
      const response = await client.put<Cart>(
        `${buildInternalCartUrl(cartId)}/prescribed-details-requested/${preferences}`,
        preferences
      );
      return sortCartItemsAndFixLegacyFields(response.data);
    },
  };
};

export type ApiClient = Omit<ReturnType<typeof createApiClient>, 'isActivated'>;
