import { elevate, FacetParams, localStorageBackedSession, SortType } from '@apptus/esales-api';
import { getConfigurationVariable } from '@packages/config';
import { isServer } from '@packages/gatsby-utils';
import { v4 as uuidv4 } from 'uuid';
import { observeCookies } from '../../cookiebot';
import { ProductPageRecommendation } from './types';

const NUMBER_OF_PRODUCTS_PER_PAGE = 30;
const CONTENTLIST_ID = 'ld-contentList';
const EXCLUDE_RX_RULE = 'rule excl custom.rX { "Ja" }';
export const HOME_PAGE = 'Webb hem';
export const CHECKOUT = 'Webb kassa';
export const WEB_CHECKOUT_ID = 'webb-kassa';

const searchOrigins = [
  'ORGANIC',
  'PHRASE_SUGGEST',
  'DID_YOU_MEAN',
  'UNDO_AUTO_CORRECT',
  'RELATED_SEARCHES',
  'RECENT_SEARCH',
] as const;

type SearchOrigin = typeof searchOrigins[number];

const sortTypes: SortType[] = [
  'RELEVANCE',
  'NEWEST_FIRST',
  'PRICE_ASCENDING',
  'PRICE_DESCENDING',
  'DISCOUNT',
  'RATING',
  'NAME',
  'LENGTH_INCREASING',
  'LENGTH_DECREASING',
  'WIDTH_INCREASING',
  'WIDTH_DECREASING',
  'HEIGHT_INCREASING',
  'HEIGHT_DECREASING',
  'DEPTH_INCREASING',
  'DEPTH_DECREASING',
  'VOLUME_INCREASING',
  'VOLUME_DECREASING',
  'WEIGHT_INCREASING',
  'WEIGHT_DECREASING',
  'TITLE',
];

// Type guard to check if a value is a valid SearchOrigin
export const isSearchOrigin = (value: string | null): value is SearchOrigin => {
  if (!value) return false;

  return searchOrigins.includes(value as SearchOrigin);
};

export const isSortType = (value: string): value is SortType => {
  return sortTypes.includes(value as SortType);
};

//  initialize the session keys for a user if they are not set
//  mocked session is used for tests and for users that have not accepted preferences cookies
const mockedSession = () => ({
  customerKey: uuidv4(),
  sessionKey: uuidv4(),
});

export let voyadoSession = mockedSession;

export const PRODUCT_PAGE_UPSELL = 'productUpsell';
export const PRODUCT_PAGE_ALTERNATIVES = 'productAlternatives';

export const createVoyadoApiClient = () => {
  let client = elevate({
    clusterId: getConfigurationVariable('VOYADO_CLUSTER_ID') || 'nada', // nada for tests
    market: 'se',
    locale: 'sv-SE',
    touchpoint: 'desktop',
    session: voyadoSession,
  });

  if (!isServer()) {
    // If the user has accepted preferences cookies, we use the local storage backed session
    const onCookiesLoad = (hasAccepted: boolean) => {
      voyadoSession = hasAccepted ? localStorageBackedSession() : mockedSession;

      // Reinitialize the client with the new session
      client = elevate({
        clusterId: getConfigurationVariable('VOYADO_CLUSTER_ID') || 'nada', // nada for tests
        market: 'se',
        locale: 'sv-SE',
        touchpoint: 'desktop',
        session: voyadoSession,
      });
    };

    observeCookies(onCookiesLoad, 'preferences');
  }

  return {
    getSearchResult: ({
      searchQuery,
      origin,
      pageParam,
      sort,
      facets,
      notify = true,
    }: {
      searchQuery: string;
      origin: SearchOrigin;
      pageParam: number;
      sort?: SortType;
      facets?: FacetParams;
      notify?: boolean;
    }) => {
      return client.query.searchPage(
        {
          q: searchQuery,
          sort,
          facets,
          limit: NUMBER_OF_PRODUCTS_PER_PAGE,
          skip: pageParam * NUMBER_OF_PRODUCTS_PER_PAGE,
          notify,
          origin,
        },
        {
          contentLists: [{ id: CONTENTLIST_ID }],
        }
      );
    },

    getSearchSuggestions: ({ searchQuery, notify }: { searchQuery?: string; notify: boolean }) => {
      return client.query.autocomplete(
        {
          q: searchQuery ?? '',
          notify,
          presentCustom: ['subTitle', 'descriptiveName', 'promotionPrice', 'itemType'],
        },
        {
          contentLists: [{ id: CONTENTLIST_ID }],
        }
      );
    },

    getCartRecommendations: (cart: string[]) => {
      return client.query.cartPage(
        { cart },
        {
          cart: {
            include: true,
          },
          recommendationLists: [
            {
              id: 'cart',
              algorithm: 'CART',
              limit: 10,
              params: {
                cart,
              },
              productRules: EXCLUDE_RX_RULE,
              visualization: 'CAROUSEL',
            },
          ],
        }
      );
    },

    getLandingPageRecommendations: ({
      listId,
      pageReference = HOME_PAGE,
    }: ProductPageRecommendation) => {
      return client.query.landingPage(
        { pageReference },
        {
          recommendationLists: [
            {
              id: listId,
              limit: 10,
              algorithm: 'TOP_PRODUCTS',
              productRules: EXCLUDE_RX_RULE,
              visualization: 'CAROUSEL',
            },
          ],
        }
      );
    },

    getProductPageRecommendations: (productKey: string) => {
      return client.query.productPage(
        { productKey },
        {
          recommendationLists: [
            {
              id: PRODUCT_PAGE_UPSELL,
              limit: 10,
              algorithm: 'UPSELL',
              params: {
                productKey,
              },
              productRules: EXCLUDE_RX_RULE,
              visualization: 'CAROUSEL',
            },
            {
              id: PRODUCT_PAGE_ALTERNATIVES,
              limit: 10,
              algorithm: 'ALTERNATIVES',
              productRules: EXCLUDE_RX_RULE,
              visualization: 'CAROUSEL',
            },
          ],
        }
      );
    },

    postClickNotification: (ticket: string) => {
      return client.notify.click(ticket);
    },

    postAddToCartNotification: (ticket: string) => {
      return client.notify.addToCart(ticket);
    },
  };
};

export type VoyadoApiClient = ReturnType<typeof createVoyadoApiClient>;
