import { AnalyticsContextProvider, BaseAnalyticsEvent } from '@packages/analytics';
import { useApi } from '@packages/api';
import React, { useCallback, useMemo } from 'react';
import { TRACKERS } from './trackers';
import { AnalyticsEvents, Metadata } from './types';

declare global {
  interface Window {
    dataLayer?: Array<unknown>;
    gtag?: (...args: unknown[]) => void;
  }
}

export const AnalyticsTracker: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { itemWeb } = useApi();

  const services = useMemo(() => ({ itemWeb }), [itemWeb]);

  const handleEvent = useCallback(
    (genericEvent: BaseAnalyticsEvent, metadata: Metadata) => {
      const event = genericEvent as AnalyticsEvents;

      TRACKERS.forEach((tracker) => {
        tracker(
          {
            event,
            metadata,
          },
          services
        );
      });
    },
    [services]
  );

  return <AnalyticsContextProvider onEvent={handleEvent}>{children}</AnalyticsContextProvider>;
};
