import { FLYOUT_PRODUCT_LIST_ITEM_CLICKED } from '../../../../../../components/product/flyoutProductList/analytics';

import {
  SEARCH_FLYOUT_ACTIVATED,
  SEARCH_FLYOUT_BRAND_CLICKED,
  SEARCH_FLYOUT_CLOSED,
  SEARCH_FLYOUT_QUERY_CHANGED,
  SEARCH_FLYOUT_SUBMITTED,
  SEARCH_FLYOUT_SUGGESTION_CLICKED,
} from '../../../../../../components/search/analytics';
import { Tracker } from '../../../types';
import { createSimpleTracker } from '../utils/createSimpleTracker';
import { trackEvent } from '../utils/trackEvent';

const createSearchFlyoutEvent = <T>(name: string, info: T) => ({
  event: name,
  event_info: { ...info, event_name: name },
});

let searchOngoingTimeout: ReturnType<typeof setTimeout> | null = null;

export const searchFlyoutQueryTracker: Tracker = ({ event }) => {
  if (event.type === SEARCH_FLYOUT_QUERY_CHANGED) {
    if (searchOngoingTimeout) {
      clearTimeout(searchOngoingTimeout);
    }

    searchOngoingTimeout = setTimeout(() => {
      trackEvent(
        createSearchFlyoutEvent('searchflyout_ongoing', {
          user_interaction: 'ongoing_search',
          search_term: event.payload.searchTerm,
          search_suggestions: event.payload.hasBrandSuggestions
            ? 'brands_and_products'
            : 'only_products',
        })
      );
    }, 3000);
  }

  if (event.type === SEARCH_FLYOUT_CLOSED) {
    if (searchOngoingTimeout) {
      clearTimeout(searchOngoingTimeout);
    }
  }
};

export const searchFlyoutTracker = createSimpleTracker({
  [SEARCH_FLYOUT_ACTIVATED]: () =>
    createSearchFlyoutEvent('searchflyout_activate', {
      user_interaction: 'search_field_click',
    }),
  [SEARCH_FLYOUT_SUGGESTION_CLICKED]: ({ event, metadata }) => {
    const { payload } = event;
    return createSearchFlyoutEvent('searchflyout_performed', {
      user_interaction: 'click_text',
      autosuggest_position: payload.position,
      autosuggest_selection: payload.text,
      search_term: metadata?.query,
      item_id: 'N/A',
    });
  },
  [FLYOUT_PRODUCT_LIST_ITEM_CLICKED]: ({ event, metadata }) => {
    const { payload } = event;
    return createSearchFlyoutEvent('searchflyout_performed', {
      user_interaction: 'click_product',
      autosuggest_position: payload.position,
      autosuggest_selection: `${payload.product.title}, ${payload.product.subTitle}`,
      item_id: payload.product.productNumber,
      search_term: metadata?.query,
      search_suggestions: metadata.hasBrandSuggestions ? 'brands_and_products' : 'only_products',
    });
  },
  [SEARCH_FLYOUT_BRAND_CLICKED]: ({ metadata }) => {
    return createSearchFlyoutEvent('searchflyout_performed', {
      user_interaction: 'click_brand',
      autosuggest_position: 'N/A',
      autosuggest_selection: 'N/A',
      item_id: 'N/A',
      search_term: metadata?.query,
      search_suggestions: 'brands_and_products',
    });
  },
  [SEARCH_FLYOUT_SUBMITTED]: ({ event, metadata }) => {
    return createSearchFlyoutEvent('searchflyout_performed', {
      user_interaction: 'search_submitted',
      autosuggest_selection: 'N/A',
      item_id: 'N/A',
      search_term: event.payload.searchTerm,
      search_suggestions: event.payload.hasBrandSuggestions
        ? 'brands_and_products'
        : 'only_products',
    });
  },

  [SEARCH_FLYOUT_CLOSED]: ({ event, metadata }) => {
    const { payload } = event;
    return createSearchFlyoutEvent('searchflyout_closed', {
      user_interaction: 'search_field_closed',
      search_term: payload.searchTerm,
    });
  },
});
